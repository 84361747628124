
import { useEffect, useState } from 'react'
import ThemeMaker from '../../components/thememaker'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';

export default function KycFormData({transaction_id}) {

  const [financeUserDetails, setFinanceUserDetails] = useState([])
  const [status, setstatus] = useState("");
  useEffect(() => {
    getfinanceUserDetails();
  }, [])

  async function getfinanceUserDetails() {
    try {
      let apiEndpoint = NodebaseUrlDefiner();
      const { data } = await axios({
        url: `${apiEndpoint}/adminCredit/getKycDataById/${transaction_id}`,
        method: "GET"
      })
      setFinanceUserDetails(data ? data.data : []);
    } catch (err) {
      console.log(err)
      toast.error("Error fetching sellers!")
    }
  }

  console.log("financeUserDetails3222",financeUserDetails);
  
  const changeReturnStatus = (item, selectedStatus) => {
    // item.status = selectedStatus;
    setstatus(selectedStatus);
  };

  const updateStatus = async(form_id,submission_id,status,tbl_name,order_id) => {
      try{
        console.log("966 has been called");
        let apiEndpoint = NodebaseUrlDefiner();
        const response = await axios.post(`${apiEndpoint}/credit/unsolicated_status`, {
          form_id,
          submission_id,
          status,
          tbl_name,
          order_id
        });
        if (response.data.status == "200") {
            // console.log("updated success fully");
            toast.success("Updated successfully")
        }else{
          toast.error("Failed to update")
        }
        // console.log("10333",response);

      }catch(error){

      }
  }

  return (
    <div className="card-body p-0 p-1" style={{ minHeight: "70vh", overflowX: 'auto' }}>
      {/* <Paginated data={financeUserDetails} columns={COLUMNS} /> */}
      <div>
                <table className="table-responsive table">
                  <thead>
                    <tr role="row" className="text-capitalize text-nowrap overflow-hidden">
                      <th>Sn No.</th>
                      <th>Pan No</th>
                      <th>Aadhar No</th>
                      <th>Form Id</th>
                      <th>Submission Id</th>
                      <th>Status</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {financeUserDetails && financeUserDetails.length > 0 ? ( 
                  financeUserDetails.map((items, index) => {
                    // {financeUserDetails &&
                    //   financeUserDetails.map((items, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{items?.pan_no}</td>
                            <td>{items?.aadhar_no}</td>
                            <td>{items.form_id}</td>
                            <td>{items?.submission_id}</td>
                            <td>{items?.status}</td>
                            <td>{items?.created_at}</td>
                            <td>{items?.updated_at}</td>
                            <td>
                              <select
                                className="btn btn-outline-dark" name='status'
                                // value={items.fulfillment_state}
                                onChange={(e) =>
                                  changeReturnStatus(items, e.target.value)
                                }
                              >
                                <option value="" selected>
                                  Select status
                                </option>
                                <option value="APPROVED">
                                  Approve
                                </option>
                                <option value="REJECTED">
                                  Reject
                                </option>
                              </select>
                              <p className="d-none">{`You selected : ${status}`}</p>
                              <button
                                className="btn btn-outline-primary"
                                onClick={() =>
                                  updateStatus(
                                    items?.form_id,
                                    items?.submission_id,
                                    status,
                                    'kycformdata',
                                    ''
                                  )
                                }
                              > Update
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="9">
                          No result found
                        </td>
                      </tr>
                    )}
                    
                  </tbody>
                </table>
              </div>
    </div>
  )
}
