
import { useEffect, useState } from 'react'
import axios from 'axios';
import { NodebaseUrlDefiner } from '../../utils/config';
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';

export default function EMandateDetails() {
  let params = useParams();
  const { id: transaction_id } = params;
  const [emandateDetailsList, setEmandateDetails] = useState([]);
  const [status, setstatus] = useState("");

  useEffect(() => {
      getEmandateDetails();
  }, []); // Fetch data when the component mounts
  
  async function getEmandateDetails() {
      try {
          let apiEndpoint = NodebaseUrlDefiner();
          const response = await axios({
              url: `${apiEndpoint}/adminCredit/emandateById/${transaction_id}`,
              method: "GET"
          });
          console.log("Dataa38888",response);
          // Check if the response has the data object and status
          const data = response.data;
          // Log the raw response to see the exact structure
          console.log("Dataa300", data);  // Logs the raw data response
  
          // Handle data properly based on structure
          if (data.status == 200 && data?.data) {
              setEmandateDetails(data.data);
              console.log("Setting financeOrderList with:", data.data);
          } else {
              setEmandateDetails([]);
              console.log("No valid data found");
          }
      } catch (err) {
          console.log("Error:", err);
      }
  }

  
  useEffect(() => {
      console.log("Updated financeOrderList:", emandateDetailsList);
  }, [emandateDetailsList]); // Logs the updated state after setFinanceOrderList
  
  const changeStatus = (item, selectedStatus) => {
    // item.status = selectedStatus;
    setstatus(selectedStatus);
  };
  
  const updateStatus = async(form_id,submission_id,status,tbl_name,order_id) => {
    try{
      let apiEndpoint = NodebaseUrlDefiner();
      const response = await axios.post(`${apiEndpoint}/credit/unsolicated_status`, {
        form_id,
        submission_id,
        status,
        tbl_name,
        order_id
      });
      if (response.data.status == "200") {
          // console.log("updated success fully");
          toast.success("Updated successfully")
      }else{
        toast.error("Failed to update")
      }
      // console.log("10333",response);

    }catch(error){

    }
}
  return (
    <div className="card-body p-0 p-1" style={{ minHeight: "70vh", overflowX: 'auto' }}>
    {/* <Paginated data={financeUserDetails} columns={COLUMNS} /> */}
    <div>
              <table className="table-responsive table">
                <thead>
                  <tr role="row" className="text-capitalize text-nowrap overflow-hidden">
                    <th>Sn No.</th>
                    <th>Form Id</th>
                    <th>Submission Id</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                {emandateDetailsList && emandateDetailsList.length > 0 ? ( 
                  emandateDetailsList.map((items, index) => {
                  // {emandateDetailsList &&
                  //   emandateDetailsList.map((items, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items?.form_id}</td>
                          <td>{items?.submission_id}</td>
                          <td>{items?.status}</td>
                          <td>{items?.created_at}</td>
                          <td>{items?.created_at}</td>
                          <td>
                              <select
                                className="btn btn-outline-dark" name='status'
                                // value={items.fulfillment_state}
                                onChange={(e) =>
                                  changeStatus(items, e.target.value)
                                }
                              >
                                 <option value="" selected>
                                  Select Status
                                </option>
                                <option value="APPROVED">
                                  Approve
                                </option>
                                <option value="REJECTED">
                                  Reject
                                </option>
                              </select>
                              <p className="d-none">{`You selected : ${status}`}</p>
                              <button
                                className="btn btn-outline-primary"
                                onClick={() =>
                                  updateStatus(
                                    items?.form_id,
                                    items?.submission_id,
                                    status,
                                    'e_mandate_details',
                                    ''
                                  )
                                }
                              > Update
                              </button>
                            </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7">
                        No result found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
  </div>
  )
}

