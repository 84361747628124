import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import ThemeMaker from "../../components/thememaker";
import { config } from "../../utils/config";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from 'uuid';
import aws from "aws-sdk";
import { useParams } from "react-router-dom";
const { apiEndpoint, awsKeyId, awsSecretKey } = config
export default function ThemeManagement() {
    const { apiEndpoint } = config;
    const [isSubmitting, setisSubmitting] = useState(false);
    const params: any = useParams();

    const [ptData, setptData] = useState({
        panelLogo: "",
        sidebarColor: "",
        navbarColor: "",
        // CheckAuth: false
    });

    // useEffect(() => {
    //     if (params?.id) {
    //         getProductTypeById()
    //     }
    // }, [])


    function changeHandler(e: any) {
        if (e.target.name !== "CheckAuth") {
            setptData((prev) => {
                return { ...prev, [e.target.name]: e.target.value }
            })
        } else {
            setptData((prev) => {
                return { ...prev, [e.target.name]: e.target.checked }
            })
        }
    }

    const ImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `panelLogo/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let { Location } = await s3.upload(params).promise();
            setptData((prev) => {
                return { ...prev, [e.target.name]: Location }
            })
            return Location;
        } catch (exception) {
            console.log(exception);
            return false;
        }
    };

    const UserInterface = async () => {
        try {
            let apiEndpoint = baseUrlDefiner();
            const { data } = await axios({
                url: `${apiEndpoint}/get_theme`,
                method: "GET",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
            });
            if (data?.data) {
                localStorage.setItem("userInterface", JSON.stringify(data.data));
            }
            window.location.reload()
        } catch (error) {
            console.error("Error fetching user profile:", error);
            return null;
        }
    };

    async function submitHandler() {
        let apiEndpoint = baseUrlDefiner();
        const payload = {
            panel_logo: ptData?.panelLogo,
            side_bar_color: ptData?.sidebarColor,
            nav_bar_color: ptData?.navbarColor
        }
        try {
            if (ptData?.panelLogo === "") {
                return toast.error(("Logo is required!"));
            }
            // if (ptData?.CheckAuth) {
            //     return toast.error(("Please accept the terms before submitting!"));
            // }
            setisSubmitting(true)
            const { data } = await axios({
                url: `${apiEndpoint}/theme_insert`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: payload
            })
            if (data.status === 200) {
                toast.success("successfully Changed");
                UserInterface()
                setisSubmitting(false);
            } else {
                toast.error("Error In Changing");
                setisSubmitting(false)
            }
        } catch (err) {
            toast.error("Server not working, Please try again later !")
        }
    }


    async function updateHandler() {
        let apiEndpoint = baseUrlDefiner();
        // if (ptData.name === "") {
        //     toast.error(("Product Type Name is required!"));
        //     return
        // }

        try {
            setisSubmitting(true)
            const { data } = await axios({
                url: `${apiEndpoint}/updateprotype`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    ...ptData,
                    id: params.id
                }
            })

            if (data.status === 200) {
                toast.success("Product Type Updated");
                setisSubmitting(false);

            } else {
                toast.error("Error In Updating Product Type");
                setisSubmitting(false)
            }
        } catch (err) {
            toast.error("Server not working, Please try again later !")
        }


    }

    async function getProductTypeById() {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getprotype`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    id: params.id
                }
            })
            if (data.status === 200) {
                // setptData({
                //     // name: data.data[0].name,
                //     // ondc_mapping: data.data[0].ondc_mapping,
                //     status: data.data[0].status,
                // })
            } else {
                toast.error("Error In Loading ");

            }
        } catch (err) {

            toast.error("Server not working, Please try again later !")
        }
    }

    console.log("ptData", ptData)
    return (
        <ThemeMaker>
            <div className="p-3">
                <div className="row justify-content-center">
                    <div className="col-lg-12">
                        <div className="card shadow-sm">
                            <div className="card-header bg-primary text-white d-flex justify-content-between align-items-center">
                                <h6 className="m-0">Theme Management</h6>
                            </div>
                            <div className="card-body" style={{ minHeight: "70vh" }}>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="font-weight-bold">
                                                Panel Logo <span className="text-danger">*</span>
                                            </label>
                                            <input style={{ lineHeight: "1" }}
                                                type="file"
                                                name="panelLogo"
                                                className="form-control"
                                                placeholder="Enter Product Type Name"
                                                onChange={(e) => ImageHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="font-weight-bold">
                                                Sidebar Interface <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                type="color"
                                                name="sidebarColor"
                                                className="form-control form-control-color"
                                                value={ptData.sidebarColor || "#ffffff"}
                                                onChange={changeHandler}
                                                title="Choose Sidebar Color"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="font-weight-bold">NavBar Interface</label>
                                            <input type="color" name="navbarColor" className="form-control form-control-color"
                                                value={ptData.navbarColor || "#000000"}
                                                onChange={changeHandler}
                                                title="Choose Navbar Color"
                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-md-12 col-12 mx-2">
                                        <div className="d-flex align-items-center p-3 rounded">
                                            <input type="checkbox" className="form-check-input" name="CheckAuth"
                                                checked={ptData?.CheckAuth}
                                                onChange={changeHandler}
                                                id="statusCheckbox" />
                                            <label className="form-check-label ml-2 mb-0" htmlFor="statusCheckbox">
                                                <strong>Status</strong>
                                            </label>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 mt-4">
                                        <button className={`btn ${params.id === undefined ? "btn-primary" : "btn-success"} px-4`}
                                            onClick={params.id === undefined ? submitHandler : updateHandler}
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting ? "Please Wait..." : params.id === undefined ? "Submit" : "Update"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </ThemeMaker>
    )
}
