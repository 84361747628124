import { useState, useEffect } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import Cookies from 'js-cookie';
import { toast } from 'react-toastify';
import { useParams } from "react-router-dom";
import { config } from "../../utils/config";
import { v4 as uuidv4 } from 'uuid';
import aws from "aws-sdk";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown"
import { tokenDecode } from "../../utils/helper";
const { apiEndpoint, awsKeyId, awsSecretKey } = config

interface ICustomer {
    id: any;

}
const UpdateSeller = () => {
    let params: ICustomer = useParams();
    const { id } = params;
    console.log(id);
    const decode = tokenDecode();
    const seller_id = decode?.data?.user?.seller_id
    const rawState = {
        "id": 7,
        "company_name": "",
        "pan_name": "",
        "pan_no": "",
        "pan_image": "",
        "gst_no": "",
        "contact_no": "",
        "street": " ",
        "city": " ",
        "area_code": " ",
        "state": " ",
        "latitude": " ",
        "longitude": " ",
        "provider_id": "",
        "personal_address": " ",
        "address_proof_type": "",
        "address_proof_image": "",
        "incorporation_date": " ",
        "upi_address": " ",
        "bank_account": " ",
        "ifsc_code": " ",
        "beneficiary_name": " ",
        "bank_name": " ",
        "branch_name": " ",
        "images": " ",
        "status": '',
        "approve_status": 0,
        "short_description": " ",
        "description": " ",
        "seller_source": " ",
        "domain": " ",
        "token": " ",
        "commission": "",
        "ondc_commission": '',
        "fssai_licence": " ",
        "email": "",
        "name": ""

    }
    const rawErrorData = {
        "company_name": false,
        "pan_name": false,
        "pan_no": false,
        // "pan_image": false,
        "gst_no": false,
        "contact_no": false,
        "street": false,
        "city": false,
        "area_code": false,
        "state": false,
        "latitude": false,
        "longitude": false,
        "provider_id": false,
        "personal_address": false,
        "incorporation_date": false,
        "upi_address": false,
        "bank_account": false,
        "ifsc_code": false,
        "beneficiary_name": false,
        "bank_name": false,
        "branch_name": false,
        "images": false,
        "status": false,
        "approve_status": false,
        "short_description": false,
        "description": false,
        // "commission": false,
        "ondc_commission": false,
        "address_proof_type": false,
        // "address_proof_image": false,
        // "fssai_licence": false,
        "email": false,
        "name": false
    }
    const [sellerData, setSellerData] = useState(rawState);
    const [sellerDataError, setSellerDataError]: any = useState(rawErrorData);
    const errorHandler = (e: any) => {
        if (e.target.value) {
            setSellerDataError({ ...sellerDataError, [e.target.name]: "valid" });
        } else {
            setSellerDataError({ ...sellerDataError, [e.target.name]: true });
        }
    }


    const fetchSellerData = async () => {
        let apiEndpoint = baseUrlDefiner();
        try {
            const { data } = await axios({
                url: `${apiEndpoint}/getseller`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                    seller_id: id
                }
            });
            console.log("Update to data", data);

            if (data.status === 200) {
                setSellerData({ ...data.data[0], id: id });
            }
        } catch (error) {

        }
    }



    const inputSHandler = (e: any) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };
    const errorSHandler = (e: any) => {
        if (e.target.value) {
            setUserDataError({ ...userDataError, [e.target.name]: false });
        } else {
            setUserDataError({ ...userDataError, [e.target.name]: true });
        }
    }
    const inputHandler = (e: any) => {
        setSellerData({ ...sellerData, [e.target.name]: e.target.value });
    }

    const newUserCreatehandler = async () => {

    }
    const ImageHandler = async (e: any) => {
        try {
            const file = e.target.files[0];
            let myFile = file.name.split(".");
            let myFileType = myFile[myFile.length - 1];
            const s3 = new aws.S3({
                accessKeyId: awsKeyId,
                secretAccessKey: awsSecretKey
            })
            const params = {
                Bucket: "ondc-marketplace",
                Key: `sellerimages/${uuidv4()}.${myFileType}`,
                Body: file,
                ContentType: file?.type
            }
            let { Location } = await s3.upload(params).promise();
            return Location;
        } catch (exception) {
            console.log(exception);
            return false;
        }
    };
    const uploadImage = async (e: any) => {
        let apiEndpoint = baseUrlDefiner();
        const image = await ImageHandler(e);
        console.log(image);
        setSellerData({ ...sellerData, [e.target.name]: image });

    }


    //  --------------------------------------------------CODE FOR SHOP DETAILS START --------------------------------	
    const [Oweekdays, setOweekdays] = useState([
        { day: "Monday", id: "1" }, { day: "Tuesday", id: "2" }, { day: "Wednesday", id: "3" }, { day: "Thursday", id: "4" }, { day: "Friday", id: "5" }, { day: "Saturday", id: "6" }, { day: "Sunday", id: "7" },
    ])
    const [Cweekdays, setCweekdays] = useState([
        { cday: "Monday", id: "1" }, { cday: "Tuesday", id: "2" }, { cday: "Wednesday", id: "3" }, { cday: "Thursday", id: "4" }, { cday: "Friday", id: "5" }, { cday: "Saturday", id: "6" }, { cday: "Sunday", id: "7" },
    ])
    const [selectedCDays, setSelectedCDays] = useState();
    const [selectedODays, setSelectedODays] = useState(Oweekdays);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDates, setSelectedDates]: any = useState([]);
    const [pageNamem, setpageName] = useState(true);
    const rawData = {
        opening_time: '10:00',
        closing_days: "",
        opening_days: "",
        closing_time: '19:00',
        images: "",
        customer_care_contact: "",
        delivery_fee: "",
        packing_fee: "",
        status: true,
        holidays: "10-12-23"
    };
    const errorRawData = {
        opening_time: false,
        closing_days: false,
        opening_days: false,
        closing_time: false,
        incorporation_date: false,
        images: false,
        commission: false,
        ondc_commission: false,
        // fssai_licence: false,
        seller_commission: false,
        returnable: false,
        replaceable: false,
        customer_care_contact: false,
        cancelable: false,
        return_window: false,
        seller_pickup_return: false,
        time_to_ship: false,
        cod: false,
        // status: false,	
        status: false
    };
    const [userData, setUserData]: any = useState(rawData);
    const [userDataError, setUserDataError] = useState(errorRawData);
    async function getDetails() {
        let apiEndpoint = baseUrlDefiner();
        const { data } = await axios({
            url: `${apiEndpoint}/sellerShop`,
            method: "POST",
            headers: {
                "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
            },
            data: { seller_id: id }
        })
        if (data.status === 200) {
            setUserData(data?.response)
            setpageName(false);
            if (data?.response.holidays !== null) {
                let t = data?.response.holidays;
                let z = t?.split(",")
                setSelectedDates(z)
            }
            // Split opening_days and closing_days by comma and trim any whitespace	
            const openingDays = data.response.opening_days.split(",").map((day: any) => day.trim());
            if (data.response.closing_days !== null) {
                const closingDays = data.response.closing_days.split(",").map((day: any) => day.trim());
                const filteredCDays: any = Cweekdays.filter(
                    (day) => closingDays.includes(day.id)
                );
                setSelectedCDays(filteredCDays.map((item: any) => item));
            }
            const filteredODays = Oweekdays.filter(
                (day) => openingDays.includes(day.id)
            );
            setSelectedODays(filteredODays.map((item: any) => item));
        }
    }
    const onSelectHandler = (selectedList: any, selectedItem: any) => {
        let daySelected = selectedList.map((item: any) => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map((item: any) => item));
    }
    const onRemoveHandler = (selectedList: any, selectedItem: any) => {
        let daySelected = selectedList.map((item: any) => item.id);
        let z = daySelected.toString()
        setUserData({ ...userData, opening_days: z });
        setSelectedODays(selectedList.map((item: any) => item));
    }
    const handleDateSelect = async (date: any) => {
        setStartDate(date)
        let z = date;
        let c = z.toLocaleDateString()
        const dateStr = c;
        // Create a new Date object by parsing the date string	
        const dates = new Date(dateStr);
        // Extract the year, month, and day from the Date object	
        const year = dates.getFullYear();
        const month = String(dates.getMonth() + 1).padStart(2, '0');
        const day = String(dates.getDate()).padStart(2, '0');
        // Format the date in the desired format	
        const formattedDate = `${year}-${month}-${day}`;
        setSelectedDates((startDate: any) => [...startDate, formattedDate]);
    };
    const RemoveItem = (indexToRemove: any) => {
        let x = [...selectedDates]
        x.splice(indexToRemove, 1);
        setSelectedDates(x)
    }
    //  --------------------------------------------------CODE FOR SHOP DETAILS END ------------------------------------	

    const updateSellerHandlre = async () => {
        
        let apiEndpoint = baseUrlDefiner();
        console.log("setSellerData", sellerData);
        const {
            company_name,
            pan_name,
            pan_no,
            pan_image,
            gst_no,
            contact_no,
            street,
            city,
            area_code,
            state,
            latitude,
            longitude,
            personal_address,
            incorporation_date,
            upi_address,
            bank_account,
            ifsc_code,
            beneficiary_name,
            bank_name,
            branch_name,
            images,
            status,
            approve_status,
            short_description,
            description,
            commission,
            ondc_commission,
            address_proof_type,
            address_proof_image,
            fssai_licence,
            email,
            name
        } = sellerData;
        const {
            opening_time,
            closing_days,
            opening_days,
            closing_time,
            customer_care_contact,
            delivery_fee,
            packing_fee,
            holidays
        } = userData

        if (name && email && address_proof_type && branch_name && beneficiary_name && ifsc_code && bank_account && personal_address && longitude && latitude && state && area_code && city && street && contact_no  && pan_no  && pan_name && company_name && opening_time && closing_time && opening_days) {
            const { data } = await axios({
                url: `${apiEndpoint}/sellerupdate`,
                method: "POST",
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: sellerData
            });
            console.log("--- updated data ----");
            if (data.status === 200) {
                toast.success("Seller updated successfully !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else {
                toast.error("403 error !", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } else {
            toast.error("Please fill all the fields !", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    useEffect(() => {
        fetchSellerData();
        getDetails()
    }, []);
    return (
        <>
            <ThemeMaker>

                <div className="row w-100 m-0 mt-1" >
                    <div className="col-md-12 ">
                        <div className="card shadow mb-4">
                            <div className="card-header py-3">
                                <h6 className="m-0 font-weight-bold text-primary">
                                    Update seller
                                </h6>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Name</label>
                                            <span className="text-danger" > * </span>
                                            {
                                                sellerDataError?.name !== "valid" && sellerDataError?.name !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="name" placeholder="Name"
                                                value={sellerData.name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Company name</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.company_name !== "valid" && sellerDataError?.company_name !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="company_name" placeholder="Company name"
                                                value={sellerData.company_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Email address</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.email !== "valid" && sellerDataError?.email !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" name="email" className="form-control" placeholder="Email"
                                                value={sellerData.email}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Contact No.</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.contact_no !== "valid" && sellerDataError?.contact_no !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="number" name="contact_no" className="form-control" placeholder="Contact number"
                                                value={sellerData.contact_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                onKeyDown={(e: any) => {
                                                    if (e.target.value.length >= 12 && e.keyCode !== 8 && e.keyCode !== 46) {
                                                        e.preventDefault();
                                                    }
                                                }}

                                            />
                                        </div>
                                    </div>

                                    {/* <div className="col-md-6">
                                        <div className="form-group">
                                            <label >UID</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.provider_id !== "valid" && sellerDataError?.provider_id !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" name="provider_id" className="form-control" placeholder="UID"
                                                value={sellerData.provider_id}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >GST</label>
                                            {/* <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.gst_no !== "valid" && sellerDataError?.gst_no !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            <input type="text" className="form-control" name="gst_no" placeholder="GST number"
                                                value={sellerData.gst_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                maxLength={15}


                                            />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Fassai License Number
                                                {/* <span className="text-danger" > * </span>
                                                {
                                                    userDataError?.fssai_licence ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                                            </label>
                                            <input
                                                type="text"
                                                name="fssai_licence"
                                                className="form-control"
                                                value={sellerData.fssai_licence}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                placeholder="Enter License Number"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >PAN Number <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_no !== "valid" && sellerDataError?.pan_no !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_no" placeholder="PAN number"
                                                value={sellerData.pan_no}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                                maxLength={10}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >Name as per PAN <span className="text-danger" >*</span>
                                                {
                                                    sellerDataError?.pan_name !== "valid" && sellerDataError?.pan_name !== false ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                }
                                            </label>
                                            <input type="text" className="form-control" name="pan_name" placeholder="Name as per pan"
                                                value={sellerData.pan_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label >PAN image
                                                {sellerData.pan_image && <a href={sellerData.pan_image} target="_blank" className="small_text" rel="noreferrer" > View Document</a>}
                                            </label>
                                            <input type="file" name="pan_image" className="form-control"
                                                onChange={(e) => uploadImage(e)}


                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <p> Address Proof <span className="text-danger" >*</span>
                                            {
                                                sellerDataError?.address_proof_type !== "valid" && sellerDataError?.address_proof_type !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                        </p>
                                        <div className="form-group">
                                            <select name="address_proof_type" id="" className="form-control"
                                                value={sellerData.address_proof_type}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            >
                                                <option value="">Select address proof</option>
                                                <option value="Aadhar card">Aadhar card</option>
                                                <option value="Ration card">Ration card</option>
                                                <option value="Telephone bill">Telephone bill</option>
                                                <option value="Bank Passbook">Bank Passbook</option>
                                                <option value="Electricity bill">Electricity bill</option>
                                                <option value="Lease or license agreement">Lease or license agreement</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <p> Address Proof image
                                            {sellerData.address_proof_image && <a href={sellerData.address_proof_image} target="_blank" className="small_text" rel="noreferrer" > View Document</a>}
                                        </p>
                                        <div className="form-group">
                                            <input type="file" name="address_proof_image" className="form-control"
                                                onChange={(e) => uploadImage(e)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <p className="m-0" style={{ paddingBottom: "9px" }}> Incorporation date
                                        {/* <span className="text-danger" >*</span>
                                            {
                                                sellerDataError?.incorporation_date !== "valid" && sellerDataError?.incorporation_date !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                        </p>
                                        <div className="form-group">
                                            <input type="date" id="start" className="form-control" name="incorporation_date"
                                                onChange={(e) => setSellerData({ ...sellerData, [e.target.name]: e.target.value })}
                                                value={sellerData.incorporation_date}
                                                onBlur={(e) => errorHandler(e)}
                                            />

                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Commission
                                                {/* <span className="text-danger" > * </span>
                                                {
                                                    sellerData?.ondc_commission ? (
                                                        <span className="text-danger tiny_size" > This field is required ! </span>
                                                    ) : null
                                                } */}
                                            </label>
                                            <input
                                                type="text"
                                                name="ondc_commission"
                                                className="form-control"
                                                placeholder="Enter Commission"
                                                value={sellerData.ondc_commission}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                        
                                    </div>




                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Status</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.status !== "valid" && sellerDataError?.status !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select className="form-control" name="status" id="exampleFormControlSelect1"
                                                onChange={(e) => setSellerData({ ...sellerData, [e.target.name]: e.target.value })}
                                                value={sellerData.status}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="true" >Active</option>
                                                <option value="false" >Deactive</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Approve Status</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.approve_status !== "valid" && sellerDataError?.approve_status !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select className="form-control" name="approve_status" id="exampleFormControlSelect1"
                                                onChange={(e) => setSellerData({ ...sellerData, [e.target.name]: e.target.value })}
                                                value={sellerData.approve_status}
                                                onBlur={(e) => errorHandler(e)}

                                            >
                                                <option value="0" >New Request</option>
                                                <option value="1" >Active</option>
                                                <option value="2" >Blocked</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label >Short description</label>
                                            {/* <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.short_description !== "valid" && sellerDataError?.short_description !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            <input type="text" className="form-control" name="short_description" placeholder="Short description"
                                                value={sellerData.short_description}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12" style={{ display: "none" }}>
                                        <div className="form-group">
                                            <label >Long description</label>
                                            {/* <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.description !== "valid" && sellerDataError?.description !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            <input type="text" className="form-control" name="description" placeholder="Long description"
                                                value={sellerData.description}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label >Personal Address</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.personal_address !== "valid" && sellerDataError?.personal_address !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="personal_address" placeholder="Personal Address"
                                                value={sellerData.personal_address}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="font-weight-bold">Business Address </p>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Latitude</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.latitude !== "valid" && sellerDataError?.latitude !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="latitude" placeholder="Latitude"
                                                value={sellerData.latitude}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Longitude</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.longitude !== "valid" && sellerDataError?.longitude !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="longitude" placeholder="Longitude"
                                                value={sellerData.longitude}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >State</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.state !== "valid" && sellerDataError?.state !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <select id="State"
                                                className="form-control" name="state" placeholder="State"
                                                value={sellerData.state}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            >
                                                <option value="">Select State</option>
                                                <option value="AP">Andhra Pradesh</option>
                                                <option value="AR">Arunachal Pradesh</option>
                                                <option value="AN">Andaman and Nicobar Islands</option>
                                                <option value="AS">Assam</option>
                                                <option value="BR">Bihar</option>
                                                <option value="CG">Chhattisgarh</option>
                                                <option value="CH">Chandigarh</option>
                                                <option value="DH">Dadra and Nagar Haveli</option>
                                                <option value="DD">Daman and Diu</option>
                                                <option value="DL">Delhi</option>
                                                <option value="GA">Goa</option>
                                                <option value="GJ">Gujarat</option>
                                                <option value="HR">Haryana</option>
                                                <option value="HP">Himachal Pradesh</option>
                                                <option value="JK">Jammu and Kashmir</option>
                                                <option value="JH">Jharkhand</option>
                                                <option value="KA">Karnataka</option>
                                                <option value="KL">Kerala</option>
                                                <option value="LD">Lakshadweep</option>
                                                <option value="MP">Madhya Pradesh</option>
                                                <option value="MH">Maharashtra</option>
                                                <option value="MN">Manipur</option>
                                                <option value="ML">Meghalaya</option>
                                                <option value="MZ">Mizoram</option>
                                                <option value="NL">Nagaland</option>
                                                <option value="OR">Orissa</option>
                                                <option value="PB">Punjab</option>
                                                <option value="PY">Pondicherry</option>
                                                <option value="RJ">Rajasthan</option>
                                                <option value="SK">Sikkim</option>
                                                <option value="TN">Tamil Nadu</option>
                                                <option value="TS">Telangana</option>
                                                <option value="TR">Tripura</option>
                                                <option value="UK">Uttarakhand</option>
                                                <option value="UP">Uttar Pradesh</option>
                                                <option value="WB">West Bengal</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >City</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.city !== "valid" && sellerDataError?.city !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="city" placeholder="City"
                                                value={sellerData.city}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Area code</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.area_code !== "valid" && sellerDataError?.area_code !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="area_code" placeholder="Area code"
                                                value={sellerData.area_code}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="form-group">
                                            <label >Street</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.street !== "valid" && sellerDataError?.street !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="street" placeholder="Street"
                                                value={sellerData.street}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <p className="font-weight-bold" > Bank details </p>
                                    </div>
                                    {/* <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Bank Name</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.bank_name !== "valid" && sellerDataError?.bank_name !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="bank_name" placeholder="Bank Name"
                                                value={sellerData.bank_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div> */}
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Branch name </label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.branch_name !== "valid" && sellerDataError?.branch_name !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="branch_name" placeholder="Branch name"
                                                value={sellerData.branch_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Bank account</label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.bank_account !== "valid" && sellerDataError?.bank_account !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="bank_account" placeholder="Bank account"
                                                value={sellerData.bank_account}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >IFSC code </label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.ifsc_code !== "valid" && sellerDataError?.ifsc_code !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="ifsc_code" placeholder="IFSC code"
                                                value={sellerData.ifsc_code}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >Beneficiary name </label>
                                            <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.beneficiary_name !== "valid" && sellerDataError?.beneficiary_name !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            }
                                            <input type="text" className="form-control" name="beneficiary_name" placeholder="Beneficiary name"
                                                value={sellerData.beneficiary_name}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-3">
                                        <div className="form-group">
                                            <label >UPI address </label>
                                            {/* <span className="text-danger" > * </span>

                                            {
                                                sellerDataError?.upi_address !== "valid" && sellerDataError?.upi_address !== false ? (
                                                    <span className="text-danger tiny_size" > This field is required ! </span>
                                                ) : null
                                            } */}
                                            <input type="text" className="form-control" name="upi_address" placeholder="UPI address"
                                                value={sellerData.upi_address}
                                                onChange={(e) => inputHandler(e)}
                                                onBlur={(e) => errorHandler(e)}

                                            />
                                        </div>
                                    </div>

                                    {/* ------------------------------------------- HTML FOR SHOP DETALS START ------------------------------------- */}
                                    <div className="col-md-12">
                                        <p className="font-weight-bold" > Shop details </p>
                                    </div>
                                    <div className="row pl-2">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Opening Time
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        userDataError?.opening_time ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </label>
                                                <input
                                                    type="time"
                                                    className="form-control"
                                                    name="opening_time"
                                                    placeholder="Enter Opening Time"
                                                    value={userData.opening_time}
                                                    onChange={(e: any) => inputSHandler(e)}
                                                    onBlur={(e: any) => errorSHandler(e)}
                                                />


                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Closing Time
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        userDataError?.closing_time ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </label>
                                                <input
                                                    type="time"
                                                    name="closing_time"
                                                    className="form-control"
                                                    placeholder="Enter Closing Time"
                                                    value={userData.closing_time}
                                                    onChange={(e: any) => inputSHandler(e)}
                                                    onBlur={(e: any) => errorSHandler(e)}
                                                />
                                            </div>
                                        </div>

                                        {/* <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Customer Care Contact
                                                    <span className="text-danger" > * </span>
                                                    {
                                                        userDataError?.customer_care_contact ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    }
                                                </label>
                                                <input
                                                    type="text"
                                                    name="customer_care_contact"
                                                    className="form-control"
                                                    value={userData.customer_care_contact}
                                                    onChange={(e: any) => inputSHandler(e)}
                                                    onBlur={(e: any) => errorSHandler(e)}
                                                    placeholder="Customer Care Number"
                                                />
                                            </div>
                                        </div> */}



                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Delivery Charges

                                                </label>
                                                <input
                                                    type="text"
                                                    name="delivery_fee"
                                                    className="form-control"
                                                    value={userData.delivery_fee}
                                                    onChange={(e: any) => inputSHandler(e)}
                                                    onBlur={(e: any) => errorSHandler(e)}
                                                    placeholder="Enter Delivery Charges"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Packing Charges

                                                </label>
                                                <input
                                                    type="text"
                                                    name="packing_fee"
                                                    className="form-control"
                                                    value={userData.packing_fee}
                                                    onChange={(e: any) => inputSHandler(e)}
                                                    onBlur={(e: any) => errorSHandler(e)}
                                                    placeholder="Enter Packing Charges"
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="mb-1">
                                                    <label className="m-0">Opening Days
                                                        <span className="text-danger" > * </span>
                                                        {
                                                            userDataError?.opening_days !== false ? (
                                                                <span className="text-danger tiny_size" > This field is required ! </span>
                                                            ) : null
                                                        }
                                                    </label>
                                                </label>
                                                {/* <input
                                                type="text"
                                                name="opening_days"
                                                className="form-control"
                                                placeholder="Enter Open Days"
                                                value={userData.opening_days}
                                                onChange={(e:any) => inputHandler(e)}
                                                 onBlur={(e: any) => errorHandler(e)}
                                            /> */}

                                                <Multiselect options={Oweekdays} selectedValues={selectedODays} displayValue="day" onSelect={onSelectHandler} onRemove={onRemoveHandler}></Multiselect>


                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label>Closing Days
                                                    {/* <span className="text-danger" > * </span> */}
                                                    {/* {
                                                        userDataError?.closing_days !== false ? (
                                                            <span className="text-danger tiny_size" > This field is required ! </span>
                                                        ) : null
                                                    } */}
                                                </label>

                                                <Multiselect options={Cweekdays} selectedValues={selectedCDays} displayValue="cday" onSelect={(selectedList, selectedItem) => {
                                                    let daySelected = selectedList.map((item: any) => item.id)
                                                    let z = daySelected.toString()
                                                    setUserData({ ...userData, closing_days: z });
                                                    setSelectedCDays(selectedList.map((item: any) => item));
                                                }}
                                                    onRemove={(selectedList, selectedItem) => {
                                                        let daySelected = selectedList.map((item: any) => item.id);
                                                        let z = daySelected.toString()
                                                        setUserData({ ...userData, closing_days: z });
                                                        setSelectedCDays(selectedList.map((item: any) => item));
                                                    }}></Multiselect>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-group">

                                                <label>Holidays   </label>
                                                {/* <span className="text-danger" > * </span> */}

                                                <div style={{ marginLeft: "5px", display: "inline-block" }}>

                                                    <DatePicker selected={startDate} onChange={(date) => handleDateSelect(date)} />

                                                </div>

                                                <ul className="multiselect-container multiSelectContainer" style={{ display: "flex", marginTop: "3px", border: "1px solid grey", padding: "10px", borderRadius: "5px", flexWrap: "wrap", minWidth: "80%", minHeight: "40px" }}>
                                                    {selectedDates.map((date: any, index: any) => (
                                                        <>
                                                            <li key={date.toString()}
                                                                style={{ padding: "10px", background: "#0096fb", color: "white", borderRadius: "40px", marginBottom: "22px", lineHeight: "10px", fontSize: "13px", display: "flex", justifyContent: "center" }}>
                                                                {date}

                                                                <span onClick={() => RemoveItem(index)}
                                                                    style={{ margin: "-2px 0px 0px 10px" }} >x</span>
                                                            </li>&nbsp;&nbsp;

                                                        </>
                                                    ))}
                                                </ul>





                                            </div>
                                        </div>


                                    </div>



                                    {/* ------------------------------------------------- HTML FOR SHOP DETALS END ------------------------------------- */}
                                    <div className="col-md-12">


                                        <button type="submit" className="btn btn-success"
                                            onClick={updateSellerHandlre}>
                                            Update
                                        </button>

                                    </div>
                                    {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            </ThemeMaker>
        </>
    )
}

export default UpdateSeller;
