import React, { useEffect } from "react";
import { useState } from "react";
import "./switch.css";
import { tokenDecode } from "../../utils/helper";
import styles from "../../components/ProductViewTemplate/ProductTemplate.module.css";
import { data } from "jquery";
import { toast } from "react-toastify";
import CloseIcon from '@mui/icons-material/Close';
import Circularprogress from "../../components/Generics/Circularprogress";
import axios from "axios";
import { baseUrlDefiner } from "../../utils/config";
import { Link } from "react-router-dom";
import editfull from "../../icons/edit.png";
import ReactPaginate from 'react-paginate';
import styled from "styled-components";
import { config } from "../../utils/config";
import Applyfilter from "../../components/Applyfilter/Applyfilter";
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import ThemeMaker from "../../components/thememaker";
import Cookies from 'js-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import edit from "../../icons/edit.png"



const { apiEndpoint, baseNodeapiUrl } = config;
const modalStyle = {
  position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 1000, bgcolor: 'background.paper', boxShadow: 24, borderRadius: "10px", p: 1, color: "black",
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageContainer = styled.div
  `
  max-height: 100px;
  width: 25%;
  
  img{
    width: 100%!important;
    height: 100px;
  object-fit: contain;
}
  `

const Instorecatalog = (props) => {
  const decoded = tokenDecode();
  let current_sellerid = decoded.data.user.seller_id;
  const { permission: { product }, } = decoded?.data;
  // console.log("permissions------->", data.permission)
  const [searchValue, setsearchValue] = useState("");
  const [openInput, setopenInput] = useState(null);
  const [openQuantyInput, setopenQuantityInput] = useState(null);
  const [openReferenceIdInput, setopenReferenceIdInput] = useState(null);
  const [productlist, setproductlist] = useState([]);
  const [quickUpdatedMaxPrice2, setquickUpdatedMaxPrice2] = useState("");
  const [quickUpdatedMaxPrice, setquickUpdatedMaxPrice] = useState("");
  const [productisLoading, setproductisLoading] = useState(false);
  const [quantiy, setQuantiy] = useState("");
  const [referenceId, setreferenceId] = useState("");
  const [noproducts, setNoproducts] = useState(false);
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [Alertopen, setAlertOpen] = useState(false);
  const [syncing, setsyncing] = useState(false);
  const [ConfirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);
  const [productToDelete, setProductToDelete] = useState(null);
  const [EcomModalOpen, setEcomModalOpen] = useState(false);
  const handleAlertOpen = () => {
    setOpen(false);
    setAlertOpen(true);

  };

  const handleAlertClose = () => {
    setAlertOpen(false);
    setsyncingData({
      seller_id: current_sellerid.toString(),
      price: false,
      inventory: false,
      sku: false,
      title: false,
      weight: false
    })
  };

  const [syncingData, setsyncingData] = useState({
    seller_id: current_sellerid.toString(),
    price: false,
    inventory: false,
    sku: false,
    title: false,
    weight: false

  })
  const handleSyncModalOpen = () => setOpen(true);
  const handleSyncModalClose = () => {
    setOpen(false);
    setsyncingData({
      seller_id: current_sellerid.toString(),
      price: false,
      inventory: false,
      sku: false,
      title: false,
      weight: false
    })
  }
  const syncmodalstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: "10px",
    boxShadow: 24,
    p: 2,
  };
  useEffect(() => {
    getproductlist();
  }, []);




  async function getproductlist() {
    let apiEndpoint = baseUrlDefiner();
    let parentId = decoded?.data?.user?.parent_id;
    let SellerId = decoded?.data?.user?.seller_id;
    let payload;
    if (parentId > 1) {
      payload = { seller_id: parentId }

    } else {
      payload = { seller_id: SellerId }

    }
    setproductisLoading(true)
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/listproductsallsvar`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: payload,
      });

      if (data.status === 200) {
        setproductisLoading(false)
        setItemOffset(0)
        setproductlist(data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }


  function maxPriceHandler(id) {
    setopenInput(openInput === id ? null : id);
    const maxPriceProduct = productlist.filter((item) => item.id === id);
    setquickUpdatedMaxPrice2(...maxPriceProduct);
    let mp;
    // console.log("maxPriceProduct---", maxPriceProduct)
    if (maxPriceProduct?.tax_included_price) {
      mp = maxPriceProduct.tax_included_price;
      // console.log("mp--->", mp)
      setquickUpdatedMaxPrice(mp);
    } else if (maxPriceProduct?.mrp) {
      mp = maxPriceProduct.mrp;
      setquickUpdatedMaxPrice(mp);
    }
  }
  async function QuickUpdateHandler(id, mrp) {
    let apiEndpoint = baseUrlDefiner();

    if (parseInt(quickUpdatedMaxPrice) <= parseInt(mrp)) {
      let updatedDataToSend;
      let dataForSeller2 = {
        product_id: id,
        price: quickUpdatedMaxPrice,
        status: true,
      };
      if (current_sellerid === 1) {
        updatedDataToSend = {
          ...quickUpdatedMaxPrice2,
          tax_included_price: quickUpdatedMaxPrice,
        };
      } else {
        updatedDataToSend = dataForSeller2;
      }
      // console.log(updatedDataToSend)
      const { data } = await axios({
        url: `${apiEndpoint}/quickeditprice`,
        method: "post",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
        data: updatedDataToSend,
      });

      if (data.status === 200) {
        setopenInput(null);
        toast.success("Price Updated successfully !");
        setTimeout(() => {
          getproductlist();
        }, 1000);
      }

      // if (current_sellerid===1) {
      //   if (data.status===200) {
      //     categoryListing();
      //   }
      // }
    } else {
      alert("Please Enter an amount less then MRP");

    }
  }

  function quantityModalHandler(id) {
    setopenQuantityInput(openQuantyInput === id ? null : id);

  }
  function referenceIdModalHandler(id) {
    setopenReferenceIdInput(openReferenceIdInput === id ? null : id);

  }

  async function quantityUpdateHandler(id) {
    let apiEndpoint = baseUrlDefiner();
    try {
      if (quantiy === "") {
        toast.error("Please Enter Quatity!")
      } else {
        const data = await axios({
          url: `${apiEndpoint}/updatequantity `,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            id: id,
            quantity: quantiy
          }
        })
        if (data.status === 200) {
          toast.success("Qunatity Updated");
          setopenQuantityInput(null);
          setQuantiy("")
          setTimeout(() => {
            getproductlist();
          }, 1000);
        } else {
          toast.error("Something Went Wrong")
        }
      }
    } catch (err) {
      console.log(err)
    }

  }
  async function ReferenceUpdateHandler(id) {
    let apiEndpoint = baseUrlDefiner();
    try {
      if (referenceId === "") {
        toast.error("Please Enter Reference Id!")
      } else {
        const data = await axios({
          url: `${apiEndpoint}/updateRefId `,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            id: id,
            reference_id: referenceId
          }
        })
        if (data.data.status === 200) {
          toast.success("ReferenceId Updated");
          setopenReferenceIdInput(null);
          setreferenceId("")
          setTimeout(() => {
            getproductlist();
          }, 1000);
        } else {
          toast.error("Something Went Wrong")
        }
      }
    } catch (err) {
      console.log(err)
    }

  }

  function handleSort(e) {

    if (e.target.value == 0) {
      const sortedLowToHigh = [...productlist].sort((a, b) => {
        return parseInt(a.selling_price) - parseInt(b.selling_price);
      });
      setproductlist(sortedLowToHigh);
    } else if (e.target.value == 1) {
      const sortedHightoLow = [...productlist].sort((a, b) => {
        return parseInt(b.selling_price) - parseInt(a.selling_price);
      });

      setproductlist(sortedHightoLow);
    } else {
      getproductlist();
    }
  }

  function searchHandler(e) {
    setsearchValue(e.target.value);
    if (e.target.value == "") {
      getproductlist();
      setNoproducts(false)
    }
  }

  async function searchResult(e) {
    let apiEndpoint = baseUrlDefiner();
    if (e.key == "Enter") {
      try {
        const { data } = await axios({
          url: `${apiEndpoint}/searchproductpro`,
          method: "POST",
          headers: {
            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
          },
          data: {
            title: searchValue,
            seller_id: current_sellerid,
          },
        });
        if (data) {
          setproductlist(data?.data);
          setItemOffset(0)
          // console.log(data.data)
          if (data?.data?.length < 1) {
            setNoproducts(true)
          }
        }
      } catch (error) { }
    }

  }

  //---------------------------------------------- code  for pagination------------------------------------------------
  const [itemCheckboxes, setItemCheckboxes] = useState({});

  useEffect(() => {
    const checkboxes = {};
    productlist.forEach((item) => {
      checkboxes[item.id] = item.status;
    });
    setItemCheckboxes(checkboxes);
  }, [productlist]);

  const [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + 10;

  // const currentItems = productlist.slice(itemOffset, endOffset);

  const [currentItems, setcurrentItems] = useState([]);

  useEffect(() => {
    let z = productlist.slice(itemOffset, endOffset)
    setcurrentItems([...z])
  }, [productlist, itemOffset])

  const pageCount = Math.ceil(productlist.length / 10);

  const handlePageClick = (event) => {
    window.scrollTo(0, 0)
    const newOffset = (event.selected * 10) % productlist.length;
    setItemOffset(newOffset);
  };

  //---------------------------------------------- code for pagination------------------------------------------------

  function updateProductList(productList) {
    setproductlist(productList)
  }

  const exportToExcel = async () => {
    let apiEndpoint = baseUrlDefiner();
    try {
      const { data } = await axios({
        url: `${apiEndpoint}/export_product`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
        },
      })

      const headers = Object.keys(data.message[0]);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(data.message, { header: headers });

      XLSX.utils.book_append_sheet(wb, ws, 'Orders');

      const fileName = `seller_instoreProducts.xlsx`;

      XLSX.writeFile(wb, fileName);
      toast.success("Products Exported Successfully")
    } catch (err) {
      toast.error("Server Error, please try later!")
    }
  };



  const handleImport = async () => {
    let apiEndpoint = baseUrlDefiner();
    // console.log(file)
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      complete: ({ data }) => {
        console.log(data)

        let x = data.map(item => {
          return {
            reference_id: item.reference_id,
            position: item?.position,
            selling_price: item?.selling_price
          }
        })

        // console.log("xxxxxxxxxxxx------>", x)
        sendDataToDb(x)
      },
    });
  };


  async function sendDataToDb(jsonData) {
    let apiEndpoint = baseUrlDefiner();
    try {

      console.log(jsonData)
      let result = await axios({
        url: `${apiEndpoint}/updateprice`,
        method: 'POST',
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,

        },
        data: jsonData,

      })
      getproductlist();
    } catch (error) {

      // console.log("this is from catch")
      toast.error("403 error !");
    }


  }


  async function syncPriceHandler() {
    let apiEndpoint = baseUrlDefiner();
    setsyncing(true)
    try {
      const data = await axios({
        url: `${baseNodeapiUrl}/shopify/sync`,
        method: "PUT",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          "seller_id": syncingData.seller_id,
          "price": syncingData.price.toString(),
          "inventory": syncingData.inventory.toString(),
          "sku": syncingData.sku.toString(),
          "title": syncingData.title.toString(),
          "weight": syncingData.weight.toString()
        }

      })
      if (data.status === 200 && data.data.status === 200) {
        toast.success("Product Synced Successfully");
        setAlertOpen(false);
        setsyncing(false)
        getproductlist();
        setsyncingData({
          seller_id: current_sellerid.toString(),
          price: false,
          inventory: false,
          sku: false,
          title: false,
          weight: false
        })
      } else {
        toast.error("Something Went Wrong")
        setsyncing(true)
      }
    } catch (err) {
      console.log(err)
      setsyncing(true)
    }
  }


  function syncCheckHandler(e) {
    const { name } = e.target;
    console.log(name)
    console.log(e.target.value)
    setsyncingData((prev) => {
      return {
        ...prev, [name]: !prev[name]
      }
    })
  }


  async function deleteProductHandler(item) {
    let apiEndpoint = baseUrlDefiner();
    try {
      const data = await axios({
        url: `${apiEndpoint}/deleteVarNonPro`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          id: item.id,
          "has_variations": item.has_variations,
        }

      })
      if (data.status === 200) {
        toast.success("Product Deleted Successfully");
        getproductlist();
        handleShipementModalClose()
      } else {
        toast.error("Something Went Wrong")

      }
    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
  }

  async function bulkdeleteHandler(item) {
    let apiEndpoint = baseUrlDefiner();
    try {
      const data = await axios({
        url: `${apiEndpoint}/bulk_delete`,
        method: "POST",
        headers: {
          "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: selectedBox

      })
      if (data.status === 200) {
        toast.success("Selected Product Deleted!");
        getproductlist();
        handleShipementModalClose()
      } else {
        toast.error("Something Went Wrong");
      }
    } catch (err) {
      console.log(err)
      toast.error("Something Went Wrong")
    }
  }

  function handleShipementModalClose() {
    setConfirmDeleteModalOpen(false);
    setConfirmDeleteModalOpen(null)
  }
  const [selectedBox, setselectedBox] = useState([])
  function checkHandler(row) {
    console.log("dhadha", row.id);
    let hasId = selectedBox.some((item) => item == row.id)
    if (!hasId) {
      setselectedBox((prev) => {
        return [...prev, row.id]
      })
    } else {
      setselectedBox((prev) => {
        return prev.filter((item) => item !== row.id)
      })
    }

  }

  const fullUrl = window.location.host;

  const [ecomsyncingData, setecomsyncingData] = useState({
    "category": "",
    "active": 1,
    "cpIds": "",
    "product_type": ""

  })

  function syncEcomCheckHandler(e) {
    const { name } = e.target;
    console.log(name)
    console.log(e.target.value)
    setecomsyncingData((prev) => {
      return {
        ...prev, [name]: e.target.value
      }
    })
  }


  const [syncAllEcomProducts, setsyncAllEcomProducts] = useState(true);
  const [syncingecom, setsyncingecom] = useState(false);
  async function syncEcomHandler() {
    setsyncing(true)

    if (!syncAllEcomProducts && ecomsyncingData?.category === "" && ecomsyncingData?.cpIds === "" && ecomsyncingData?.product_type === "") {
      toast.error("Please check  sync all products or pass parameters");
      setAlertOpen(false);
      setsyncingecom(false)
      setsyncing(false)
      return
    }
    let payload = syncAllEcomProducts ? {
      active: 1
    } : {
      ...ecomsyncingData
    }
    try {
      const data = await axios({
        url: `${apiEndpoint}/get-product-easyecom`,
        method: "POST",
        headers: {
          "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`
        },
        data: {
          ...payload
        }

      })
      toast.success("Synced successfully!")
      setsyncingecom(false)
      setAlertOpen(false);
      setsyncing(false)
    } catch (err) {
      console.log(err)
      if (err?.response?.data?.status == 401) {
        toast.error(err?.response?.data?.message)
      }
      setsyncingecom(false)
      setAlertOpen(false);
      setsyncing(false)
      toast.error(err?.response?.data?.error)
    }
  }


  return (
    <ThemeMaker>
      <>
        <div className="row mx-auto p-3">


          {/* -------------------------------------------- search & filter start ---------------------------------------- */}
          <div className="col-12">
            <input type="search"
              name="name" placeholder="Search Product" className="w-75 mr-2 rounded-pill" value={searchValue} onChange={searchHandler} onKeyPress={searchResult} style={{ fontSize: "14px" }} />

            <button className="btn btn-primary mt-1" onClick={() => setTimeout(() => {
              exportToExcel()
            }, 2000)}>Export Data</button>

            {(fullUrl === "preprodadmin.xpressbaazaar.com" || fullUrl === "localhost:3000") && <>  {product.update && <button className="ml-2  btn btn-secondary" onClick={handleSyncModalOpen}>Sync Product</button>} </>}
            {/* <button className="ml-2  btn btn-secondary" onClick={handleAlertOpen}>Sync Product</button> */}


            {/* <div className="importingSection">
            <input type="file" id="inputFile" onChange={(e) => {
              // console.log("change working");
              setFile(e.target.files[0]);
            }} style={{ marginTop: "30px" }} />
            <button className="btn btn-secondary" onClick={handleImport} > Submit </button>

          </div> */}


          </div>
          <div className="col-12 btn-toolbar p-0 d-none justify-content-between align-items-center" role="toolbar">

            <Applyfilter updateProductList={updateProductList} getproductlist={getproductlist} url="filterinstore"></Applyfilter>

            <div className="btn-group" role="group" style={{ width: "37%" }}>
              <select type="button" className="btn btn-secondary w-100 " style={{ fontSize: "14px", margin: "5px 0px" }} onChange={handleSort}              >
                <option value="" disabled selected>Sort</option>
                <option value="0">Low - High</option>
                <option value="1">High - Low</option>
              </select>
            </div>
          </div>

          {/* ------------------------------ search & filter end ---------------------------------------- */}
          {selectedBox.length > 0 ? <div className="d-flex w-100 justify-content-start mt-2" style={{ gap: "10px" }}>
            <span className="functionalButtons" onClick={() => {
              let z = productlist.filter(item => item);
              let m = z.map((item) => item?.id);
              setselectedBox(m)
            }} >Select All</span>
            <span className="functionalButtons" onClick={() => setselectedBox([])} >Clear</span>

          </div> : null}

          {/* -------------------------------------------- product mapping start ----------------------------- */}
          {productisLoading ?
            <Circularprogress />
            : <> {!noproducts ? <> {currentItems?.map((item, index) => {
              {/* const images =  item.image.split(",") */ }
              const idpresent = selectedBox.some((ids) => ids === item.id)

              return (
                <div className="d-flex w-100  my-2 py-2"
                  style={{ gap: "3px", position: "relative", background: "white", padding: "4px", }}
                  key={item?.id}  >

                  <div className="d-flex align-items-start p-2">
                    <input type="checkbox" style={{ cursor: "pointer", width: "15px", height: "15px" }} checked={idpresent} onChange={() => checkHandler(item)} />
                  </div>

                  <ImageContainer>

                    <img src={item?.has_variations === true ? item?.proImage?.split(",")[0] : item?.image?.split(",")[0]} alt="" className="w-100" />

                  </ImageContainer>
                  <div className="prod_desc flex-column">
                    <p className="m-0"><span className="genericlabel">{item?.name}</span> </p>
                    <p className="m-0"> <span className="genericlabel">  Product ID :</span>  {item?.ondc_product_id}</p>
                    {/* <p className="m-0"> <span className="genericlabel">Category : </span> {item?.cat_name} </p> */}
                    {/* <p className="m-0"> <span className="genericlabel"> Mrp : </span> {item?.mrp} </p> */}
                    <p className="m-0"> <span className="genericlabel">Selling Price :</span> Rs{item?.selling_price ? item?.selling_price : item?.selprice}   </p>
                    <p className="m-0"> <span className="genericlabel">Transfer Price : </span> {item.transfer_price} </p>
                    <p className="m-0"> <span className="genericlabel">Variations :</span>  {item.has_variations == true ? "Available" : "No Variants Available"} </p>

                    <br />
                    {/* <Link to={`addinstoreproduct/${item.id}?q=${item.has_variations}`}>Check Product</Link> */}
                    <br />
                  </div>
                  {product?.update ? <div
                    className=" d-flex  "
                    style={{ top: "3px", right: "5px", gap: "3px" }}
                  >
                    <label className="switch">
                      <input type="checkbox" checked={itemCheckboxes[item.id]} onChange={async (e) => {
                        let apiEndpoint = baseUrlDefiner();
                        const itemId = item.id;
                        const isChecked = !itemCheckboxes[item.id];
                        setItemCheckboxes({ ...itemCheckboxes, [itemId]: isChecked });
                        const { data } = await axios({
                          url: `${apiEndpoint}/changeproductstatus`,
                          method: "POST",
                          headers: {
                            "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                            "Content-type": "application/json",
                          },
                          data: { product_id: itemId, isChecked: isChecked },
                        });

                      }}
                      />
                      <span className="slider round"></span>
                    </label>

                    <Link to={`addinstoreproduct/${item.id}?q=${item.has_variations}`}> <img src={edit} alt="" style={{ width: "32px", height: "32px" }} /></Link>

                    {/* <span>Delete</span> */}

                    {/* <img
                      src={edit}
                      alt=""
                      onClick={(e) => {
                        e.preventDefault();
                        maxPriceHandler(item.id);
                      }}
                    /> */}

                    <div className=" flex-column d-none" style={{ gap: "10px" }}>
                      <span className="badge text-light" style={{ cursor: "pointer", background: "#105293" }} onClick={(e) => {
                        e.preventDefault();
                        maxPriceHandler(item.id);
                      }}>Edit Selling Price</span>


                      <span className="badge text-light" style={{ cursor: "pointer", background: "#105293" }} onClick={(e) => {
                        e.preventDefault();
                        quantityModalHandler(item.id);
                      }}>Edit Quantity</span>

                      <span className="badge text-light" style={{ cursor: "pointer", background: "#105293" }} onClick={(e) => {
                        e.preventDefault();
                        referenceIdModalHandler(item.id);
                      }}>Edit Reference Id</span>
                    </div>

                    {openInput === item.id && (
                      <div className="position-relative">
                        <div class="pop-up">
                          <span className={styles.popupClose} onClick={() => { setopenInput(null); setquickUpdatedMaxPrice("") }}   > x </span>
                          <div class="pop-up-content">
                            <div class="form-group d-flex " style={{ gap: "3px" }}>
                              <p style={{ fontWeight: "600", fontSize: "12px", color: "black", }} className="p-1 m-0"  >
                                Selling Price
                              </p>
                              <input type="number" class="form-control form-control-sm "
                                value={quickUpdatedMaxPrice} className="w-50 px-2" onChange={(e) => {
                                  setquickUpdatedMaxPrice(e.target.value);
                                }}
                              />
                            </div>
                            <button style={{
                              marginLeft: "2px", backgroundColor: "blue", padding: "2px 5px", borderRadius: "3px", border: "none", color: "white", fontSize: "15px",
                            }}
                              onClick={() => {
                                QuickUpdateHandler(item.id, item.mrp);
                              }}
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    {openQuantyInput === item.id && (
                      <div className="position-relative">
                        <div class="pop-up">
                          <span
                            className={styles.popupClose}
                            onClick={() => { setopenQuantityInput(null); setQuantiy("") }}
                          >
                            x
                          </span>
                          <div class="pop-up-content">
                            <div class="form-group d-flex " style={{ gap: "3px" }}>
                              <p
                                style={{
                                  fontWeight: "600",
                                  fontSize: "12px",
                                  color: "black",
                                }}
                                className="p-1 m-0"
                              >
                                Quantity
                              </p>
                              <input
                                type="number"
                                class="form-control form-control-sm "
                                value={quantiy}
                                className="w-50 px-2"
                                onChange={(e) => {
                                  setQuantiy(e.target.value);
                                }}
                              />
                            </div>
                            <button
                              style={{
                                marginLeft: "2px",
                                backgroundColor: "blue",
                                padding: "2px 5px",
                                borderRadius: "3px",
                                border: "none",
                                color: "white",
                                fontSize: "15px",
                              }}
                              onClick={() => {
                                quantityUpdateHandler(item.id);
                              }}
                            >

                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                    {openReferenceIdInput === item.id && (
                      <div className="position-relative">
                        <div class="pop-up">
                          <span
                            className={styles.popupClose}
                            onClick={() => { setopenReferenceIdInput(null); setQuantiy("") }}
                          >
                            x
                          </span>
                          <div class="pop-up-content">
                            <div class="form-group d-flex " style={{ gap: "3px" }}>
                              <p
                                style={{
                                  fontWeight: "600",
                                  fontSize: "12px",
                                  color: "black",
                                }}
                                className="p-1 m-0"
                              >
                                Reference Id
                              </p>
                              <input
                                type="number"
                                class="form-control form-control-sm "
                                value={referenceId}
                                className="w-50 px-2"
                                onChange={(e) => {
                                  setreferenceId(e.target.value);
                                }}
                              />
                            </div>
                            <button
                              style={{
                                marginLeft: "2px",
                                backgroundColor: "blue",
                                padding: "2px 5px",
                                borderRadius: "3px",
                                border: "none",
                                color: "white",
                                fontSize: "15px",
                              }}
                              onClick={() => {
                                ReferenceUpdateHandler(item.id);
                              }}
                            >

                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div style={{ display: "none" }}>
                      <Link to={`/updatestoreproduct/${item.id}`}>
                        <div>
                          <img src={editfull} alt="" />
                        </div>
                      </Link>
                    </div>
                  </div> : null}
                </div>
              );
            })}</> : <p className="w-100" style={{ fontSize: "16px", color: "black", fontWeight: "600", textAlign: "center" }}>No such products</p>} </>}

          {/* -------------------------------------------- product mapping end ---------------------------------------- */}

          {/* -------------------------------------------- pagination start ---------------------------------------- */}

          {!productisLoading && <div className='pagination_main'>
            <ReactPaginate breakLabel="..." nextLabel=">" onPageChange={handlePageClick} pageRangeDisplayed={1} marginPagesDisplayed={1} pageCount={pageCount} previousLabel="<" renderOnZeroPageCount={null} activeLinkClassName="current_active" />

          </div>}
          {/* -------------------------------------------- pagination end ---------------------------------------- */}

        </div>

        <Modal open={open} onClose={handleSyncModalClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description"
        >
          <Box sx={syncmodalstyle}>
            <div>
              <p className="text-center" style={{ fontSize: "20px", color: 'black', fontWeight: "500" }}>Sync Inventory</p>
            </div>

            <div>

              <div >
                <select className="form-control" onChange={(e) => { setEcomModalOpen(e.target.value) }}  >
                  <option>Select CMS</option>
                  <option value="ecom">EasyEcom</option>
                  <option value="shopify">Shopify</option>
                </select>
              </div>

              {EcomModalOpen === "shopify" && <> <div>

                <div className="syncContainers mt-3">
                  <input type="checkbox" name="price" checked={syncingData.price} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update Price</label>
                </div>
                <div className="syncContainers mt-3" >
                  <input type="checkbox" name="inventory" checked={syncingData.inventory} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update Quantity</label>
                </div>
                <div className="syncContainers mt-3">
                  <input type="checkbox" name="title" checked={syncingData.title} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update Title</label>
                </div>
                <div className="syncContainers mt-3" >
                  <input type="checkbox" disabled name="sku" checked={syncingData.sku} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0" >Update SKU</label>
                </div>
                <div className="syncContainers mt-3">
                  <input type="checkbox" disabled name="weight" checked={syncingData.weight} onChange={syncCheckHandler} />  <label htmlFor="" className="p-0 m-0">Update Weight</label>
                </div>



              </div>
                <div className="mt-3 d-flex justify-content-end">
                  {/* <button className="btn btn-primary" onClick={() => syncEcomHandler()}>Sync</button> */}
                  <button className="btn btn-primary" onClick={handleAlertOpen}>Sync products from Shopify</button>
                </div>
              </>
              }

              {
                EcomModalOpen === "ecom" && <div className="mt-2">
                  <div>
                    <div className="d-flex align-items-center" style={{ gap: "10px" }}>
                      <label className="text-center m-0 genericlabel" >Sync All Products</label>
                      <input type="checkbox" checked={syncAllEcomProducts} onChange={() => { setsyncAllEcomProducts(!syncAllEcomProducts) }} />
                    </div>
                    <div className=" mt-3">
                      <label htmlFor="" className="p-0 m-0 genericlabel" >Category</label>
                      <input type="text" className="form-control" name="category" value={ecomsyncingData.category} onChange={syncEcomCheckHandler} />
                    </div>
                    <div className=" mt-3" >
                      <label htmlFor="" className="p-0 m-0 genericlabel" >CP Id</label>
                      <input type="text" className="form-control" name="cpIds" value={ecomsyncingData.cpIds} onChange={syncEcomCheckHandler} />
                    </div>
                    <div className=" mt-3">
                      <label htmlFor="" className="p-0 m-0 genericlabel" >Product Type</label>
                      <input type="text" className="form-control" name="product_type" value={ecomsyncingData.product_type} onChange={syncEcomCheckHandler} />
                    </div>

                  </div>

                  <div className="mt-3 d-flex justify-content-end">
                    {/* <button className="btn btn-primary" onClick={() => syncPriceHandler()}>Sync</button> */}
                    <button className="btn btn-primary" onClick={handleAlertOpen}>Sync products from Easyecom</button>
                  </div>
                </div>
              }


            </div>


          </Box>

        </Modal>


        <Dialog
          open={Alertopen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleAlertClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle>{"Are you sure?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              On Clicking Agree, products will be synced.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAlertClose}>Disagree</Button>
            <button onClick={EcomModalOpen === "ecom" ? syncEcomHandler : syncPriceHandler} className="btn btn-danger"> {syncing ? "Syncing..." : "Agree"} </button>
          </DialogActions>
        </Dialog>


        {/* -------------------------------------DELETE CONFIRMATION MODAL-------------------------------------- */}
        <Modal
          open={ConfirmDeleteModalOpen}
          onClose={handleShipementModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="col-md-4 ">

            <div className="modal-content " >
              <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Delete Product..</p>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span style={{ cursor: "pointer" }} ><CloseIcon onClick={handleShipementModalClose} /></span>
                </button>
              </div>
              <div className="modal-body">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <ReportProblemRoundedIcon style={{ fontSize: "40px", color: '#dd3737' }} />
                  <h5 style={{ fontWeight: '700' }}> Products once deleted can't be restored.
                    <h6 className="text-center font-weight-bold mt-1">Are you sure?</h6>
                  </h5>
                </div>
              </div>
              <div className="modal-footer">
                {selectedBox.length > 0 ? <button className="btn btn-danger" onClick={() => { bulkdeleteHandler() }} > Bulk Delete </button> : <button className="btn btn-danger" onClick={() => { deleteProductHandler(productToDelete) }} > Delete </button>}
              </div>
            </div>
          </Box>
        </Modal>
      </>
    </ThemeMaker>
  );
};

export default Instorecatalog;
