import { useEffect, useState } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { NodebaseUrlDefiner } from "../../utils/config";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
export default function FinanceOrders() {
  let params = useParams();
  const { id: transaction_id } = params;
  const [orderList, setOrderList] = useState([]);
  const [orderId, setOrderId] = useState([]);
  
  useEffect(() => {
    getOrder();
  }, []);
  async function getOrder() {
    try {
      let apiEndpoint = NodebaseUrlDefiner();
      const { data } = await axios({
        url: `${apiEndpoint}/adminCredit/fetchOrder/${transaction_id}`,
        method: "GET",
      });
      console.log("data.status",data.status);
      if (data.status === 200 && Array.isArray(data.data)) {
        console.log("helo");
        const parsedOrderList = data.data.map((order) => {
           const provider_id = JSON.parse(order.provider).id 
           const items = JSON.parse(order.items)
           const quote = JSON.parse(order.quote)
            setOrderId(order.order_id)
           return {
            ...order,
            provider_id,
            item_id:items[0].id,
            total_loan:quote.price.value
           }
        })
        console.log("31111",parsedOrderList);
        setOrderList(parsedOrderList);
      }else{
        setOrderList([]);
      }
    //   setOrderList(data?.status === 200 ? data.data : []);
      
    } catch (err) {
      console.log(err);
      toast.error("Error fetching sellers!");
    }
  }
 console.log("order id ",orderList);
  return (
    <ThemeMaker>
      <div className="row w-100 m-0 mt-1">
        <div className="col-md-12 ">
          <div className="card shadow mb-4">
            {/* <div className="card-header p-2">
              <div
                className={`d-flex justify-content-between p-2 align-items-center}`}
              >
                <h6
                  className="m-0 font-weight-bold text-primary text-left"
                  style={{ width: "100px" }}
                >
                  Orders In process
                </h6>
                <div className="d-flex align-items-center w-100">
                  <div
                    className="d-flex flex-column flex-md-row justify-content-between align-items-center mt-4 ml-2 mt-sm-0 w-100"
                    style={{ gap: "11px" }}
                  >
                    <input
                      type="text"
                      className="form-control  w-100 w-md-50 mt-2 mt-md-0"
                      placeholder="Enter Transaction ID.."
                    />
                  </div>
                  <div
                    className="d-flex  align-items-center"
                    style={{ gap: "11px" }}
                  >
                    <button className="btn btn-primary commonBtn ">
                      Filters
                    </button>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="card-header py-3 d-flex">
              <h6 className="m-0 font-weight-bold text-primary text-left">
                 Order Id:</h6> {orderId}
            </div>
            <div
              className="card-body p-0 p-1"
              style={{overflowX: "auto" }}
            >
              <div
                className="card-body p-0 p-1"
                style={{ minHeight: "70vh", overflowX: "auto" }}
              >
                {/* <Paginated data={financeUserDetails} columns={COLUMNS} /> */}
                <div>
                  <table className="table-responsive table">
                    <thead>
                      <tr
                        role="row"
                        className="text-capitalize text-nowrap overflow-hidden"
                      >
                        <th>Sn No.</th>
                        <th>Order Id</th>
                        <th>Provider Id</th>
                        <th>Item Id</th>
                        <th>Price(Rs)</th>
                        <th>Status</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderList &&
                        orderList.map((items, index) => {
                          return (
                            <tr>
                              <td>{index + 1}</td>
                              <td>{items?.order_id}</td>
                              <td>{items?.provider_id}</td>
                              <td>{items.item_id}</td>
                              <td>{items?.total_loan}</td>
                              <td>{items?.status}</td>
                              <td>{items?.created_at}</td>
                              <td>{items?.updated_at}</td>
                              <td>
                                <Link to={`../orderdetail/${items?.order_id}`}>
                                    <button className="btn btn-primary"> View Details</button>
                                </Link>
                              </td> 
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ThemeMaker>
  );
}
