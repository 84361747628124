import { useEffect, useState } from "react";
import ThemeMaker from "../../components/thememaker";
import axios from "axios";
import { toast } from "react-toastify";
import { NodebaseUrlDefiner } from "../../utils/config";
import { useParams } from "react-router-dom";

export default function AgreementDetails() {
  let params = useParams();
  const { id: transaction_id } = params;
  const [agreementDetails, setAgreementDetails] = useState([]);
  const [status, setstatus] = useState("");
  useEffect(() => {
    getAgreementDetails();
  }, []);

  async function getAgreementDetails() {
    try {
      let apiEndpoint = NodebaseUrlDefiner();
      const response = await axios({
        url: `${apiEndpoint}/adminCredit/loanAgreementById/${transaction_id}`,
        method: "GET",
      });
      console.log("Dataa38888", response);
      // Check if the response has the data object and status
      const data = response.data;

      // Log the raw response to see the exact structure
      console.log("Dataa300", data); // Logs the raw data response

      // Handle data properly based on structure
      if (data.status == 200 && data?.data) {
        setAgreementDetails(data.data);
        console.log("Setting financeOrderList with:", data.data);
      } else {
        setAgreementDetails([]);
        console.log("No valid data found");
      }
    } catch (err) {
      console.log(err);
      toast.error("Error fetching sellers!");
    }
  }

  const changeStatus = (item, selectedStatus) => {
    setstatus(selectedStatus);
  };

  const updateStatus = async(form_id,submission_id,status,tbl_name,order_id) => {
      try{
        console.log("966 has been called");
        let apiEndpoint = NodebaseUrlDefiner();
        const response = await axios.post(`${apiEndpoint}/credit/unsolicated_status`, {
          form_id,
          submission_id,
          status,
          tbl_name,
          order_id
        });
        if (response.data.status == "200") {
            // console.log("updated success fully");
            toast.success("Updated successfully")
        }else{
          toast.error("Failed to update")
        }
        // console.log("10333",response);

      }catch(error){

      }
  }

  console.log("Agreements Details 3222", agreementDetails);
  return (
    <div
      className="card-body p-0 p-1"
      style={{ overflowX: "auto" }}
    >
      {/* <Paginated data={financeUserDetails} columns={COLUMNS} /> */}
      <div>
        {/* <table className="table-responsive table" style={{width:"100%"}}> */}
        <table className="table-responsive table">
          <thead>
            <tr
              role="row"
              className="text-capitalize text-nowrap overflow-hidden"
            >
              <th>Sn No.</th>
              <th>Form Id</th>
              <th>Submission Id</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {agreementDetails && agreementDetails.length > 0 ? ( 
              agreementDetails.map((items, index) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{items.form_id}</td>
                    <td>{items?.submission_id}</td>
                    <td>{items?.status}</td>
                    <td>{items?.created_at}</td>
                    <td>{items?.updated_at}</td>
                    <td>
                      <select
                        className="btn btn-outline-dark"
                        name="status"
                        // value={items.fulfillment_state}
                        onChange={(e) =>
                          changeStatus(items, e.target.value)
                        }
                      >
                        <option value="" selected>Select Status</option>
                        <option value="APPROVED">Approve</option>
                        <option value="REJECTED">Reject</option>
                      </select>
                      <p className="d-none">{`You selected : ${status}`}</p>
                      <button
                        className="btn btn-outline-primary"
                        onClick={() =>
                          updateStatus(
                            items?.form_id,
                            items?.submission_id,
                            status,
                            "loan_agreement_details",
                            ""
                          )
                        }
                      >
                        {" "}
                        Update
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7">
                  No result found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
