import React, { useState, useEffect } from 'react'
import ThemeMaker from '../../components/thememaker'
import { useHistory } from 'react-router-dom';
import { tokenDecode } from "../../utils/helper";
import axios from 'axios';
import { config } from "../../utils/config"
import { toast } from "react-toastify";
import Cookies from 'js-cookie';
import style from "../SellerDetails/Sellerdetails.module.css"
import { baseUrlDefiner } from '../../utils/config';
import * as XLSX from 'xlsx';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
const Sstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: "10px",
    bgcolor: 'background.paper',
    border: '1px solid #000',
    boxShadow: 24,
    p: 2,
};
export default function Settings() {

    const apiEndpoint = config.apiEndpoint;
    const nodeApi = config.baseNodeapiUrl
    console.log(nodeApi)
    const decoded = tokenDecode();
    const { data } = decoded;
    let current_sellerid = decoded.data.user.seller_id;
    // console.log(decoded.data.seller_data.provider_id)
    const rawInputData = {
        API_VERSION: "",
        Version: "",
        Access_Token: "",
        Source: "",
        Api_key: "",
        domain: ""

    }
    const rawInputErrorData = {
        shopify_store_url: false,
        shopify_access_token: false,
        shopify_version: false,
        API_VERSION: false,
        Version: false,
        Access_Token: false,
        Source: false,
        Api_key: false,
        domain: false

    }
    const [inputValue, setInputValue] = useState(rawInputData);
    const [inputErrorValue, setInputErrorValue] = useState(rawInputErrorData);
    const [cmsVal, setcmsVal] = useState("")
    const [readOnly, setreadOnly] = useState(false)
    const [showSubmit, setShowSubmit] = useState(true)

    const rawCmsInputValues = {
        easyecom: {
            email: "",
            password: "",
            location_key: "",
            source: "",
            api_key: "",
            domain: ""
        }
    }
    const rawCmsInputErrors = {
        easyecom: {
            email: false,
            password: false,
            location_key: false,
            source: false,
            api_key: false,
            domain: false

        }
    }
    const [cmsInputValues, setcmsInputValues] = useState(rawCmsInputValues)
    const [cmsInputErrors, setcmsInputErrors] = useState(rawCmsInputErrors)
    useEffect(() => {
        getStoreCred()
    }, [])

    async function getStoreCred() {
        try {
            let apiEndpoint = baseUrlDefiner();
            const { data } = await axios({
                method: "POST",
                url: `${config.baseNodeapiUrl}/env/getconfig`,
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                },
                data: {
                    "seller_id": current_sellerid.toString()
                }

            })

            if (data) {
                // let maskedvalue = data?.data?.shopify_access_token.replace(/.(?=.{2})/g, '*');
                setInputValue({ ...data?.data })
                // console.log(data.data)
                setreadOnly(true)
                setShowSubmit(false)
                setcmsVal("01")
            }
        } catch (err) {
            console.log("Servr error in fetching config details---->", err)
            toast.error("Server Error, please try later!")
        }

    }

    const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue((prev) => {
            return { ...prev, [e.target.name]: newValue }
        })

    }

    // const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    //     // console.log("input state----->", inputValue);
    //     try {
    //         if (inputValue.shopify_access_token !== "" && inputValue.shopify_store_url !== "" && inputValue.shopify_version !== "") {
    //             const { data } = await axios({
    //                 method: "POST",
    //                 url: `${apiEndpoint}/storeCollection`,
    //                 headers: {
    //                     "x-custom-authorization": `Bearer ${localStorage.getItem("us_st_d")}`,
    //                 },
    //                 data: inputValue

    //             })

    //             if (data.status == 200) {
    //                 alert("Data Submitted")
    //             } else {
    //                 alert("Something Went wrong")
    //             }
    //         } else {
    //             alert("Please fill all details")
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // }

    function selectCMSHandler(e: React.ChangeEvent<HTMLSelectElement>) {
        let val = e.target.value
        console.log(val)
        setcmsVal(val)
        if (val === "06") {
            getCMSData("easyecom")
        }
    }

    async function getCMSData(cmsType: any) {
        try {
            const { data } = await axios({
                method: "POST",
                url: `${apiEndpoint}/get-easyecom-data`,
                headers: {
                    "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                },
                data: {
                     "source":cmsType
                }

            })

            console.log("data of ecom------->", data)

            if(data.status ==200){
                setcmsInputValues((prev) => {
                    return { ...prev, easyecom: { ...data?.response, api_key: data?.response?.Api_key, source:data?.response?.Source  } }
                })
            }
            
        } catch (err) {
            console.log(err)
        }
    }



    function errorHandler(e: React.FocusEvent<HTMLInputElement>) {
        const newValue = e.target.value;
        if (newValue == "") {
            setInputErrorValue((prev) => {
                return { ...prev, [e.target.name]: true }
            })
        } else {
            setInputErrorValue((prev) => {
                return { ...prev, [e.target.name]: false }
            })
        }

    }

    async function submitWordpressData() {
        // console.log("payload------->", inputValue)
        try {
            if (inputValue.API_VERSION !== "" && inputValue.Version !== "" && inputValue.Access_Token !== "" && inputValue.Source !== "" && inputValue.Api_key !== "" && inputValue.domain !== "") {
                const { data } = await axios({
                    method: "POST",
                    url: `${nodeApi}/env/config`,
                    headers: {
                        "x-custom-authorization": `Bearer ${sessionStorage.getItem('us_st_d')}`,
                    },
                    data: { ...inputValue, seller_id: current_sellerid.toString(), provider_id: decoded.data.seller_data.provider_id }

                })

                if (data.status === 200) {
                    toast.success("Configuration Added")
                } else {
                    toast.error("Something Went Wrong")
                }

            } else {
                toast.error("Please fill all details")
            }
        } catch (err) {
            console.log(err)
        }
    }
    const [file, setFile] = useState(null);
    const [shipmentModalOpen, setshipmentModalOpen] = useState(false);
    const handleImport = async () => {
        try {
            if (file) {
                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: "array" });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const json = XLSX.utils.sheet_to_json(worksheet);
                    let dataToSend = json.filter((item: any) => item.name !== null)
                    sendDataToDb(dataToSend);
                   
                };
                reader.readAsArrayBuffer(file);
            }

        } catch (error) {
            console.error('Error handling import:', error);
        }
    };
    
    async function sendDataToDb(jsonData: any) {
        let apiEndpoint = baseUrlDefiner();

        try {

            let result = await axios({
                url: `${apiEndpoint}/seller_register`,
                method: 'POST',
                headers: {
                    "x-custom-authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,

                },
                data:   jsonData ,

            })

            if (result.data.status == 200) {
                toast.success(`Seller Registered successfully!`);
                setFile(null);
                let element:any = document.getElementById('inputFile')
                element.value = ''
            } else {
                console.log(result)
                toast.error("Server Not Working, Please try later!");
            }
        } catch (error: any) {
            console.log(error?.response?.data?.error[0]?.message)
            toast.error("Server Not Working, Please try later!");
        }


    }

    async function submitEasyecomData() {
        console.log("cmsInputValues.easyecom------->", cmsInputValues.easyecom)
        // return
        try {
            if (cmsInputValues.easyecom.email !== "" && cmsInputValues?.easyecom?.password !== "" && cmsInputValues?.easyecom?.api_key !== "" && cmsInputValues?.easyecom?.domain !== "" && cmsInputValues?.easyecom.source !== "" && cmsInputValues?.easyecom?.location_key !== "") {
                const { data } = await axios({
                    method: "POST",
                    url: `${apiEndpoint}/get-easyecom-token`,
                    headers: {
                        "X-Custom-Authorization": `Bearer ${sessionStorage.getItem("us_st_d")}`,
                    },
                    data: { ...cmsInputValues.easyecom }

                })
                console.log("data----->", data);
                if (data?.message ==="Invalid Credentials") {
                    toast.error("Invalid Credentials")
                    return
                }
                if (data?.status == 200) {
                    toast.success("Updated Successfully!")
                }else{
                    toast.error("Something went wrong!")
                }


            } else {
                toast.error("Please fill all details")
            }
        } catch (err) {
            console.log(err)
            toast.error("Server Not working, please try again later!")
        }
    }

    function cmsInputErrorHandler(e: React.FocusEvent<HTMLInputElement>, cmstype: any) {
        const newValue = e.target.value;
        if (newValue == "") {
            setcmsInputErrors((prev: any) => {
                return { ...prev, [cmstype]: { ...prev[cmstype], [e.target.name]: true } }
            })
        } else {
            setcmsInputErrors((prev: any) => {
                return { ...prev, [cmstype]: { ...prev[cmstype], [e.target.name]: false } }
            })
        }

    }

    const changeCmsValue = (e: React.ChangeEvent<HTMLInputElement>, cmsType: any) => {
        const newValue = e.target.value;
        setcmsInputValues((prev: any) => {
            return { ...prev, [cmsType]: { ...prev[cmsType], [e.target.name]: newValue } }
        })

    }


    return (
        <>
            <ThemeMaker>
                <div style={{ minHeight: "90vh" }}>
                    <div className="col-md-12 mt-2">
                        <div className="card shadow mb-4 h-100">
                            <div className="card-header py-3 d-flex justify-content-between">
                                <h6 className="m-0 font-weight-bold text-primary text-left">
                                    Connect Your Online Store
                                </h6>

                                <span className='badge badge-secondary d-none' onClick={() => {
                                    setshipmentModalOpen(true)
                                }}>Seller</span>
                            </div>
                            <div className='card-body' style={{ minHeight: "80vh" }}>


                                <div className='p-2'>
                                    <label htmlFor="" style={{ paddingLeft: "10px", color: "black" }}>Choose Your CMS</label>
                                    <select name="" id="" className="form-control col-md-4" value={cmsVal} onChange={(e) => selectCMSHandler(e)}>
                                    <option value="">Select</option>
                                        <option value="01">Shopify</option>
                                        <option value="06">EasyEcom</option>
                                        <option value="02">Wordpress</option>
                                        <option value="03">PrestaShop</option>
                                        <option value="04">Magento</option>
                                        <option value="05">Big Commerce</option>
                                    </select>
                                </div>

                                <div className=' col-md-12 row' style={{ paddingLeft: "10px", color: "black" }}>
                                    {(cmsVal == "01") && <>
                                        <div className='mt-3 col-md-6' >
                                            <label>Enter Api Version  <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='API_VERSION' value={inputValue?.API_VERSION} onBlur={errorHandler} />
                                                {inputErrorValue.API_VERSION && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Version <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Version' value={inputValue?.Version} onBlur={errorHandler} />
                                                {inputErrorValue.Version && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Access Token <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Access_Token' value={inputValue?.Access_Token} onBlur={errorHandler} />
                                                {inputErrorValue.Access_Token && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Source <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Source' value={inputValue?.Source} onBlur={errorHandler} />
                                                {inputErrorValue.Source && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Api Key <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Api_key' value={inputValue?.Api_key} onBlur={errorHandler} />
                                                {inputErrorValue.Api_key && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Domain <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='domain' value={inputValue?.domain} onBlur={errorHandler} />
                                                {inputErrorValue.domain && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>

                                        <div className='col-md-12'>
                                            <button className='btn btn-success ' onClick={submitWordpressData}>Submit</button>

                                        </div>



                                    </>}


                                    
                                    {(cmsVal == "06") && <>
                                        <div className='mt-3 col-md-6' >
                                            <label>Email <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeCmsValue(e, "easyecom")} name='email' value={cmsInputValues.easyecom.email} onBlur={(e) => { cmsInputErrorHandler(e, "easyecom") }} />
                                                {cmsInputErrors.easyecom.email && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Password <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeCmsValue(e, "easyecom")} name='password' value={cmsInputValues.easyecom.password} onBlur={(e) => { cmsInputErrorHandler(e, "easyecom") }} />
                                                {cmsInputErrors.easyecom.password && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Location Key <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeCmsValue(e, "easyecom")} name='location_key' value={cmsInputValues.easyecom.location_key} onBlur={(e) => { cmsInputErrorHandler(e, "easyecom") }} />
                                                {cmsInputErrors.easyecom.location_key && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Source <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeCmsValue(e, "easyecom")} name='source' value={cmsInputValues.easyecom.source} onBlur={(e) => { cmsInputErrorHandler(e, "easyecom") }} />
                                                {cmsInputErrors.easyecom.source && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Api Key <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeCmsValue(e, "easyecom")} name='api_key' value={cmsInputValues.easyecom.api_key} onBlur={(e) => { cmsInputErrorHandler(e, "easyecom") }} />
                                                {cmsInputErrors.easyecom.api_key && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <label>Domain <span className="text-danger" > * </span></label>
                                            <div className='form-group'>
                                                <input type="text" className="form-control" onChange={(e) => changeCmsValue(e, "easyecom")} name='domain' value={cmsInputValues.easyecom?.domain} onBlur={(e) => { cmsInputErrorHandler(e, "easyecom") }} />
                                                {cmsInputErrors.easyecom?.domain && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                            </div>
                                        </div>
                                        <div className='mt-3 col-md-6' >
                                            <button className='btn btn-success ms-5' onClick={submitEasyecomData}>Submit</button>
                                        </div>

                                    </>}
                                </div>


                                <div className=' col-md-12 row' style={{ paddingLeft: "10px", color: "black" }}>
                                    {
                                        (cmsVal === "02" || cmsVal === "03" || cmsVal === "04" || cmsVal === "05") ? <>
                                            <div className='mt-3 col-md-6' >
                                                <label>Enter Api Version  <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='API_VERSION' value={inputValue?.API_VERSION} onBlur={errorHandler} />
                                                    {inputErrorValue.API_VERSION && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Version <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Version' value={inputValue?.Version} onBlur={errorHandler} />
                                                    {inputErrorValue.Version && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Access Token <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Access_Token' value={inputValue?.Access_Token} onBlur={errorHandler} />
                                                    {inputErrorValue.Access_Token && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Source <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Source' value={inputValue?.Source} onBlur={errorHandler} />
                                                    {inputErrorValue.Source && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Api Key <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='Api_key' value={inputValue?.Api_key} onBlur={errorHandler} />
                                                    {inputErrorValue.Api_key && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>
                                            <div className='mt-3 col-md-6' >
                                                <label>Domain <span className="text-danger" > * </span></label>
                                                <div className='form-group'>
                                                    <input type="text" className="form-control" onChange={(e) => changeValue(e)} name='domain' value={inputValue?.domain} onBlur={errorHandler} />
                                                    {inputErrorValue.domain && <p style={{ fontSize: "10px", color: "red" }}>*This field is required</p>}
                                                </div>
                                            </div>

                                            <button className='btn btn-success ms-5' onClick={submitWordpressData}>Submit</button>


                                        </> : null
                                    }

                                </div>

                                {/*  */}
                                <div className='mt-5'>
                                    <div className='px-3'>
                                        <h6>CMS We Support</h6>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/shopify_.png" alt="megento" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/magento.png" alt="megento" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3 d-none'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/BigCom.png" alt="" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/Prestashop.png" alt="" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='col-md-3'>
                                            <div className={`${style.taxlistTemplateDesc}`}>
                                                <div className='d-flex justify-content-center'>
                                                    <img src="img/wordpres.png" alt="" />
                                                    {/* <div className="form-check">
                                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled />
                                                    </div> */}
                                                </div>
                                                {/* <div className='mt-2 d-flex justify-content-end' style={{ gap: "10px", cursor: "pointer" }}>
                                                    <button className='badge btn btn-outline-primary p-2' disabled> View </button>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeMaker>

            <Modal
                open={shipmentModalOpen}
                // onClose={handleShipementModalClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={Sstyle} className="col-md-4 w-100 w-md-25">

                    <div className="modal-content ">
                        <div className="modal-header p-2 d-flex align-items-center justify-content-between">
                            <p className="modal-title genericlabel  " style={{ letterSpacing: "1px" }}>Import Using CSV File..</p>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span style={{ cursor: "pointer" }} ></span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="importingSection">
                                <input type="file" id="inputFile" onChange={(e) => {
                                    const file:any = e.target.files && e.target.files.length > 0 ? e.target.files[0] : null;
                                    setFile(file);
                                }}
                                    style={{ marginTop: "30px" }}
                                />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-primary" onClick={handleImport} > Submit </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}
