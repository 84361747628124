// import React from 'react';
import './OrderTracking.css';
import { useEffect, useState } from 'react'
import axios from 'axios';
import { NodebaseUrlDefiner } from '../../utils/config';
import { Link } from 'react-router-dom';

import ActiveState from './ActiveState.jsx';
import FinanceUserDetails from './financeUserDetails.jsx';
import RequestedLoanAmt from './requestedLoanAmt.jsx';
import KycFormData from './KycFormData.jsx';
import AccountDetails from './AccountDetails';
import EMandateDetails from './EMandateDetails';
import AgreementDetails from './AgreementDetails';


export default function OrderTracking({ currentStep, setCurrentStep, transaction_id }) { // Accept props
    const [dataExists,setDataExists] = useState(false);
    const steps = [
        { id: 1, label: 'User Details', table: 'searchformdata', icon: '🛒' },
        { id: 2, label: 'Requested Loan Amount', table: 'requested_amount_loan', icon: '🔄' },
        { id: 3, label: 'KYC Form Details', table: 'kycformdata', icon: '✔️' },
        { id: 4, label: 'Account Details', table: 'acountDetail', icon: '🚚' },
        { id: 5, label: 'EMandate', table: 'emandate', icon: '📦' },
        { id: 6, label: 'Agreement Details', table: 'agreementdetails', icon: '🛒' },
    ];
    console.log("transaction_id2888",transaction_id);
    
    useEffect(()=>{
        const fetchData = async () => {
            try {
                let apiEndpoint = NodebaseUrlDefiner();
                const response = await axios({
                    url: `${apiEndpoint}/adminCredit/fetchOrder/${transaction_id}`,
                    method: "GET",
                });
                console.log("Dataa2222", response);
                // Check if the response has the data object and status
                const data = response.data;
                if (response.data.status === 200 && data?.data) {
                    setDataExists(data.data);
                    console.log("Setting financeOrderList with:", data.data);
                  } else {
                    setDataExists([]);
                    console.log("No valid data found");
                  }
          
            }catch(error){
                console.error("Error fetching data:", error);
                setDataExists(false);
            }
        };
        fetchData();
    },[transaction_id])
    console.log("dataExists566",dataExists.length);
    return (
        <div className="tracking-container">
            <div className="tracking-progress">
                {steps.map((step, index) => (
                    <div className={`step-container`} key={step.id}>
                        <div
                            className={`step ${index + 1 <= currentStep ? 'completed' : ''} ${index + 1 === currentStep ? 'active' : ''}`}
                            onClick={() => setCurrentStep(step.id)}
                        >
                            <div className="step-icon">{step.icon}</div>
                            <p>{step.label}</p>
                        </div>
                        {index < steps.length - 1 && <div className="step-line"></div>}
                    </div>
                ))}
            </div>
            {/* Display active step content */}
            <div className="active-step-content">
                <div className="card-header py-3">
                    <div class="row d-flex justify-content-between">
                        <h4><b>{steps.find(step => step.id === currentStep)?.label}</b></h4>
                        <div className="col-sm-6 col-6 col-md-6 d-flex justify-content-end align-items-end p-0">
                            <Link to={`/finance/order/${transaction_id}`}>
                                <button
                                    type="button"
                                    class={`badge btn btn-outline-primary btnHeight ${(dataExists.length > 0) ? '' : 'd-none'}`}>View Order
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <p>This is the content for {steps.find(step => step.id === currentStep)?.table} {transaction_id}</p> */}
                {steps.find(step => step.id === currentStep)?.table === 'searchformdata' ? (
                    <FinanceUserDetails transaction_id={transaction_id} />
                ) : steps.find(step => step.id === currentStep)?.table === 'requested_amount_loan' ? (
                    <RequestedLoanAmt transaction_id={transaction_id}/>
                ) : steps.find(step => step.id === currentStep)?.table === 'kycformdata' ? (
                    <KycFormData transaction_id={transaction_id}/>
                ) : steps.find(step => step.id === currentStep)?.table === 'acountDetail' ? (
                    <AccountDetails />
                ) : steps.find(step => step.id === currentStep)?.table === 'emandate' ? (
                    <EMandateDetails />
                ) : steps.find(step => step.id === currentStep)?.table === 'agreementdetails' ? (
                    <AgreementDetails />
                ) : (
                    <ActiveState
                        transaction_id={transaction_id}
                        table={steps.find(step => step.id === currentStep)?.table}
                    />
                )}
            </div>
        </div>
    );
}
