
import { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { NodebaseUrlDefiner } from '../../utils/config';
import { Paginated } from '../../components/Paginated';
import { useParams } from "react-router-dom";

export default function AccountDetails() {
  let params = useParams();
  const { id: transaction_id } = params;
  const [acctDetailsList, setAcctDetailsList] = useState([]);
  useEffect(() => {
      getFinanceAcctList();
  }, []); // Fetch data when the component mounts
  
  async function getFinanceAcctList() {
      try {
          let apiEndpoint = NodebaseUrlDefiner();
          const response = await axios({
              url: `${apiEndpoint}/adminCredit/getAccInfoDetailsById/${transaction_id}`,
              method: "GET"
          });
          console.log("Dataa38888",response);
          // Check if the response has the data object and status
          const data = response.data;
  
          // Log the raw response to see the exact structure
          console.log("Dataa300", data);  // Logs the raw data response
  
          // Handle data properly based on structure
          if (data.status == 200 && data?.data) {
              setAcctDetailsList(data.data);
              console.log("Setting financeOrderList with:", data.data);
          } else {
              setAcctDetailsList([]);
              console.log("No valid data found");
          }
      } catch (err) {
          console.log("Error:", err);
      }
  }

  
  useEffect(() => {
      console.log("Updated financeOrderList:", acctDetailsList);
  }, [acctDetailsList]); // Logs the updated state after setFinanceOrderList
  

  
  return (
    <div className="card-body p-0 p-1" style={{ minHeight: "70vh", overflowX: 'auto' }}>
    {/* <Paginated data={financeUserDetails} columns={COLUMNS} /> */}
    <div>
              <table className="table-responsive table">
                <thead>
                  <tr role="row" className="text-capitalize text-nowrap overflow-hidden">
                    <th>Sn No.</th>
                    <th>Form Id</th>
                    <th>Submission Id</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>
                {acctDetailsList && acctDetailsList.length > 0 ? ( 
                  acctDetailsList.map((items, index) => {
                      return (
                        <tr>
                          <td>{index + 1}</td>
                          <td>{items?.form_id}</td>
                          <td>{items?.submission_id}</td>
                          <td>{items?.status}</td>
                          <td>{items?.created_at}</td>
                          <td>{items?.created_at}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7">
                        No result found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
  </div>
  )
}
